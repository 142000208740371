.c-field-header {
  align-items: flex-start;
  display: flex;

  &:not(:last-child) {
    margin-bottom: t-spacing(sm);
  }

  &__label {
    @include t-typography(h3, bold);

    display: block;

    &--large {
      @include t-typography(h1, bold);

      margin-top: t-spacing(md);
    }
  }
}
