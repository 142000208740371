.c-dropdown {

  &__label {
    @include t-typography(h3);

    display: block;
    margin-bottom: t-spacing(sm);
  }

  &__text-label {
    @include t-typography(h3);

    margin: t-spacing(sm) 0;
  }

  .MuiTextField-root {
    background-color: $color-purple-tr1;
    color: $color-purple1;
    width: 100%;
  }

  .MuiInputBase-root {
    @include t-typography(h3);

    font-family: $font-family-default;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-notchedOutline {
    border-color: $color-purple-tr2;
  }
}

.c-dropdown__select--rounded{
  border-radius: 24px;
}