.regimen-energy-requirements {
    background-color: $color-white;

    .c-step-form {
        padding: 0 t-rem(15px);

        background-color: $color-white;
    }

    .c-radio-list__option {

        height: t-rem(48px);

        background-color: $color-purple7;

        &:before,
        & > span:first-child {
            display: none;
        }

        &--active {
            background-color: $color-purple6;
        }

        .MuiTypography-root {
            margin: auto;
        }
    }

    .c-field-header {
        display: flex;
        align-items: center;

        margin: t-spacing(lg) 0;
    }

    .c-radio-list__label,
    .c-field-header__label {
        color: $color-black;

        font-size: t-rem(18px);
        line-height: t-rem(24px);
    }

    .c-tabs .MuiTabs-root {
        box-shadow: none;
    }
}