.c-notification {
  @include t-brand-gradient;

  border-radius: $global-border-radius;
  color: $color-white;
  left: 0;
  margin: t-spacing(sm);
  position: fixed;
  top: $global-nav-height;
  width: calc(100% - #{t-spacing(lg)});
  z-index: t-layer('high');

  &__content {
    align-items: center;
    border-bottom: 1px solid $color-purple5;
    display: flex;
    padding: t-spacing(sm) t-spacing(md);
  }

  &__cta {
    @include t-typography(tab);

    background: none;
    border: none;
    color: $color-purple5;
    cursor: pointer;
    margin-left: t-spacing(md);
    padding: 0;
    text-decoration: none;
    text-transform: uppercase;
  }

  &__ctas {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: t-spacing(sm) t-spacing(md);
  }

  &__icon {
    $size: 30px;

    align-items: center;
    display: flex;
    height: $size;
    justify-content: center;
    margin-right: t-spacing(sm);
    width: $size;
  }

  &__title {
    color: $color-white;
  }
}
