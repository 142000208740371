.c-summary-block {
  @include t-brand-gradient;

  border-radius: $global-border-radius;
  overflow: hidden;

  &__icon {
    $size: 52px;

    background-color: $color-white;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 100%;
    display: block;
    height: $size;
    margin: 0 auto;
    width: $size;

    &--age {
      background-image: url('../../../images/age.svg');
    }

    &--bmi {
      background-image: url('../../../images/bmi.svg');
      background-position: -20px -11px;
      background-size: 150%;
    }

    &--gender {
      background-image: url('../../../images/gender.svg');
    }

    &--height {
      background-image: url('../../../images/height.svg');
    }

    &--weight {
      background-image: url('../../../images/weight.svg');
    }
  }

  &__item {
    flex: 1;
  }

  &__items {
    display: flex;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  &__label {
    @include t-typography(details);

    color: $color-white;
    display: block;
    margin-top: t-spacing(xs);
    text-align: center;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__products {
    margin: 0;
    padding: 0;

    > * ~ * {
      margin-top: t-spacing(sm);
    }
  }

  &__results {
    background-color: $color-white;
    border-radius: $global-border-radius;
    margin: t-spacing(md);
    padding: t-spacing(md);
  }

  &__section {
    &:not(:empty) {
      padding: t-spacing(md);

      &:not(:first-child) {
        border-top: 1px solid $color-white;
      }
    }
  }

  &__title {
    color: $color-white;
    margin-bottom: t-spacing(sm);
  }
}
