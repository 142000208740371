.c-weight-picker {
  &__controls {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
      border-bottom: 1px solid $color-grey4;
      padding-bottom: t-spacing(lg);
    }
  }

  &__dropdown {
    select,
    & {
      width: 100%;
    }

    &:not(:last-child) {
      margin-right: t-spacing(sm);
    }
  }

  &__label {
    @include t-typography(h3);

    display: block;
    margin-bottom: t-spacing(sm);
  }

  &__section-title {
    @include t-typography(h3, bold);

    display: block;
  }
}
