.c-age-picker {
  &__controls {
    display: flex;
    justify-content: space-between;
  }

  &__date-input {
    @include t-typography(tab);

    appearance: none;
    background-color: $color-white;
    border: 1px solid $color-purple5;
    border-radius: $global-border-radius;
    padding: 11px;
    width: 100%;
  }

  &__dropdown {
    select,
    & {
      width: 100%;
    }

    &:not(:last-child) {
      margin-right: t-spacing(sm);
    }
  }

  &__label {
    @include t-typography(h3);

    display: block;
    margin-bottom: t-spacing(sm);
  }

  &__mui-date {
    .MuiFormControl-root {
      width: 100%;
    }

    .MuiFormLabel-root {
      font-family: $font-family-default;
      font-weight: bold;

      &.Mui-focused {
        color: $color-purple1;
      }
    }

    .MuiInputBase-input {
      font-family: $font-family-default;
    }

    .MuiOutlinedInput-adornedEnd {
      padding-right: 0;
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
    .MuiOutlinedInput-notchedOutline {
      border-color: $color-purple5;
    }
  }

  svg {
    margin-left: t-rem(6px);
  }
}
