.c-kcal-adjuster {

  &__label {
    @include t-typography(h3);

    display: block;
    margin-bottom: t-spacing(sm);
  }

  .c-tabs__panel > * ~ *,
  .c-tabs__panel > .MuiFormControl-root {
    &:first-child {
      margin-top: 0;
    }
  }

  .MuiTextField-root {
    background-color: $color-purple-tr1;
    color: $color-purple1;
    margin-bottom: t-spacing(md);
    width: 100%;
  }

  .MuiInputBase-root {
    @include t-typography(h3);

    font-family: $font-family-default;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-notchedOutline {
    border-color: $color-purple-tr2;
  }

  .MuiSlider-root {
    color: $color-purple4;
    margin: 0 t-spacing(sm);
    width: calc(100% - #{t-spacing(lg)});
  }

  .MuiSlider-thumb .MuiSlider-valueLabel {
    @include t-typography(details, normal);

    font-family: $font-family-default;
  }
}