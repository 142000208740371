.e-button {
  &.MuiButton-root,
  & {
    @include t-typography(button, bold);

    // background: rgba(255, 255, 255, 0) linear-gradient(90deg, $color-purple4 0%, $color-purple2 100%) 0% 0% no-repeat padding-box;
    background-color: $color-purple2;
    // border: 1px solid $color-purple2;
    border-radius: $global-border-radius;
    box-shadow: 0 3px 6px $color-grey-tr2;
    color: $color-white;
    font-family: $font-family-default;
    padding: t-spacing(md);
    transition: none;
    text-align: center;
    text-transform: none;
    width: 100%;

    &.Mui-disabled {
      background: $color-grey4;
      border: 1px solid $color-grey4;
      color: $color-grey3;
    }

    &:hover {
      background: $color-purple4;
    }
  }

  &--secondary {
    &.MuiButton-root,
    & {
      background: $color-white;
      border-color: $color-white;
      color: $color-purple4;

      &:hover {
        background: $color-grey4;
      }
    }
  }

  &--small {
    &.MuiButton-root,
    & {
      @include t-typography(tab, bold);
    }
  }
}

.button--clear{
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.button{
  @extend .button--clear;
}

.button:disabled{
  opacity: 0.5;
}