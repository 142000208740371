.accordion {
  width: 100%;
  color: black;
  overflow: hidden;
  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  input:checked {
    + .accordion__label {
      &::after {
        content: "-";
      }
    }
    ~ .accordion__content {
      max-height: 100%;
      padding: t-spacing(sm);
    }
  }
  .accordion__label {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    padding: t-spacing(sm);
    cursor: pointer;
    vertical-align: middle;
    line-height: 16px;
    &::after {
      color: $color-purple2;
      content: "+";
      font-size: 22px;
      width: 1em;
      height: 1em;
      text-align: center;
      transition: all 0.1s;
    }
  }
  .accordion__content {
    max-height: 0;
    padding: 0 1em;
    color: black;
    background: white;
    transition: all 0.1s;
  }
}
