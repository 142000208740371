.c-calculator-result {
    @include t-brand-gradient;

    border-radius: $global-border-radius;
    padding: t-spacing(md);
    text-align: center;

    &__title {
        color: $color-white;
    }

    &__value {
        color: $color-white;
    }
}