.c-progress {
  $height: 4px;

  background: $color-yellow2;
  display: flex;
  height: $height;
  left: 0;
  position: absolute;
  top: auto;
  transform: translateY(-#{t-spacing(lg)});
  width: 100%;

  &__item {
    flex: 1;
    height: $height;

    &--complete {
      background: $color-yellow;
    }
  }
}