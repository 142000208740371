.c-product-rotatephone {
	display: flex;
	justify-content: center;
	margin: t-spacing(lg) 0 !important;
	&__wrapper {
		max-width: 345px;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&__icon {
		background-image: url('../../../images/rotate.svg');
		background-repeat: no-repeat;
		height: 48px;
		width: 48px;
		margin-right: t-spacing(md);
	}
	span {
		font-size: 18px;
		font-weight: 600 !important;
		max-width: 220px;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
	}
}
