.support-contact-us {
    &__container {
        padding: 0;
        border-radius: t-rem(12px);

        background-color: $color-white;

        @include t-respond-from(tablet) {
            padding: 0 t-rem(34px);
        }
    }
}