.c-form-link {
    &__content {
        height: calc(100% - #{t-spacing(lg)} * 2);
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - #{t-spacing(lg)} * 2);
    }

    &__header {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        min-height: 59px;
        padding: t-spacing(md);
        width: 100%;

        .MuiIconButton-root {
            @include t-brand-gradient;

            color: $color-white;
            margin-left: t-spacing(md);
            padding: t-spacing(sm);

            &:first-child {
                margin-left: auto;
            }
        }
    }

    &__iframe {
        height: calc(100% - 59px - #{t-spacing(md)});
        position: relative;
        width: 100%;
        z-index: t-layer('high');
    }

    &__label {
        @include t-typography(h3);

        display: block;
        margin-bottom: t-spacing(sm);
    }

    &__loader {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: t-layer('low');
    }

    @keyframes loading {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .button--external {
        margin-bottom: t-spacing(xl);
        padding: 0;
        box-shadow: none;

        &:hover {
            background-color: transparent;
        }

        .MuiButton-label {
            display: flex;

            color: black;

            & > *:first-child {
                flex-grow: 1;
                text-align: left;
            }

            & > *:last-child svg {
                fill: $color-purple2;
            }
        }
    }
}