$s-breakpoints: (
  tablet: 600px,
  desktop: 800px,
);

@mixin t-respond-from($bp: 'tablet') {
  $breakpoint: map-get($s-breakpoints, $bp);

	@if not map-has-key($s-breakpoints, $bp) {
		@error "Invalid style specified! #{$bp} doesn't exist. Choose one of #{map-keys($s-breakpoints)}";
  }

  @media (min-width: $breakpoint) {
    @content;
  }
}
