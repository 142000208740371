.MuiFormLabel-root {
  color: $color-purple1;
  font-family: $font-family-default;
  font-weight: bold;
}

label.MuiFormLabel-root.Mui-focused {
  color: $color-purple1;
}

.MuiFormLabel-root.Mui-focused {
  color: $color-purple1;
}

.MuiPickersDay-daySelected:hover,
.MuiPickersDay-daySelected,
.MuiPickersToolbar-toolbar {
  background: linear-gradient(270deg, $color-purple2, $color-purple4);
}

.MuiPaper-root {
  .MuiTypography-root {
    font-family: $font-family-default;
  }

  .MuiTypography-colorPrimary {
    color: $color-purple4;
  }

  .MuiTypography-caption,
  .MuiTypography-body2 {
    @include t-typography(details);
  }
}

.MuiFormLabel-asterisk {
  display: none;
}

.MuiOutlinedInput-root,
.MuiOutlinedInput-root.Mui-focused,
.MuiOutlinedInput-notchedOutline {
  border-color: $color-purple-tr2;
}
