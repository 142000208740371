.c-form-title {
  border-bottom: 1px solid $color-grey-tr2;
  border-top: 1px solid $color-grey-tr2;
  margin: t-spacing(lg) 0;
  padding: t-spacing(md) 0;

  &__text {
    @include t-typography(h2, bold);
  }
}
