.c-legend {
    @include t-typography(body);

    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    width: 100%;
    padding: 0 0 t-spacing(md);

    color: $color-grey1;

    &--mini {
        justify-content: flex-start;
        flex-direction: row;
    }

    &--product {
        flex-direction: row;

        margin: t-spacing(sm) 0;
        padding: 0;
    }

    .c-product-card & {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: t-rem(6px);

        .c-legend__item {
            margin-bottom: 0;
        }
    }

    &__icon {
        
        $size: t-rem(24px);


        display: flex;
        justify-content: center;
        align-items: center;

        height: $size;
        width: $size;
        margin-right: t-spacing(xs);
        border-radius: 100%;

        &::before {
            @include t-typography(body);

            display: block;

            color: black;

            font-size: t-rem(12px);
            font-weight: bold;
        }

        $variants: (
            halal: (background-color: #448AB5, content: 'H'),
            kosher: (background-color: #A94890, content: 'K'),
            vegan: (background-color: #E8C243, content: 'VG'),
            vegetarian: (background-color: #359381, content: 'V'),
        );

        @each $key, $properties in $variants {
            &--#{$key} {
                background-color: map-get($properties, 'background-color');
            
                &::before {
                    content: map-get($properties, 'content');
                    color: white;
                }
            }
        }

        &--large{
            @extend .c-legend__icon;
            $size: t-rem(40px);
            height: $size;
            width: $size;
            margin-right: 0;
            &::before {
                color: white;
            }

            $variants: (
                halal: (background-color: #448AB5, content: 'H'),
                kosher: (background-color: #bc1077, content: 'K'),
                vegan: (background-color: #E8C243, content: 'VG'),
                vegetarian: (background-color: #359381, content: 'V'),
            );

            @each $key, $properties in $variants {
                &--#{$key} {
                    @extend .c-legend__icon--large;
                    background-color:  map-get($properties, 'background-color');
                }
            }
        }
    }

    &__item {
        display: flex;
        align-items: center;

        margin-bottom: t-spacing(md);
        &--product{
            @extend .c-legend__item;
            //margin-bottom: 15px;
            justify-content: center;
            flex-direction: column;
            margin-right: 10px;

            div:nth-of-type(2){
                margin-top: t-rem(5px);
                font-size: t-rem(12px);
                color: black;
            }
        }
    }
}

.c-legend__none{
    font-weight: bold;
    margin-top: 10px;
    text-align: left;
}