.c-product-search {
	margin-top: t-rem(24px);

	&__active-filters {
		margin: 0 0 t-spacing(md);
		padding: 0;
	}

	&__active-filter {
		@include t-typography(tab, bold);

		display: inline-flex;
		align-items: center;

		margin: 0 t-spacing(xs) t-spacing(sm) 0;
		padding: t-spacing(sm) t-spacing(md);
		border-radius: 50px;

		background-color: $color-purple-tr1;
		color: $color-purple1;
	}

	&__cta {
		&--fixed.e-button.MuiButton-root {
			display: flex;
			justify-content: space-between;

			position: absolute;
			left: 50%;
			bottom: t-rem(16px);
			transform: translateX(-50%);
			z-index: 2;

			width: 100%;
			max-width: calc(#{t-rem(375px)} - #{t-rem(30px)});
			height: t-rem(48px);
			padding: 0 t-rem(22px);
			border: none;

			background-color: $color-purple4;
			box-shadow: none;

			.MuiButton-label {
				color: $color-white;
				
				font-size: t-rem(16px);
				font-weight: 600;
				letter-spacing: normal;
				line-height: 1;
				white-space: nowrap;
			}
		}
	}

	&__item {
		grid-row: span 1;
		grid-column: span 1;
	}

	&__list {
		$gap: t-rem(16px);

		display: grid;
		grid-auto-columns: max-content; //added
		grid-auto-flow: dense; // added
		grid-template-columns: repeat(2, 1fr);
		grid-gap: $gap;
		row-gap: $gap;
		grid-auto-rows: minmax(100px, auto);
		
		padding: 0;

		&--has-fixed-cta {
			padding-bottom: 50px;
		}
	}

	&__remove-filter {
		$size: t-rem(12px);

		display: inline-flex;
		align-items: center;
		justify-content: center;

		width: $size;
		height: $size;
		margin-left: t-spacing(xs);
		padding: 0;
		border: none;

		background: none;

		cursor: pointer;

		svg {
			height: $size;
			width: $size;
		}
	}

	&__section {
		margin: t-spacing(md) 0;
	}

	&__status {
		margin: 0 0 t-spacing(lg);
	}

	& &__filterbutton {
		position: absolute;
		left: 50%;
		bottom: t-rem(30px);
		transform: translateX(-50%);
		z-index: 2;

		width: t-rem(180px);
		border-radius: t-rem(24px);
		
		background-color: $color-purple1;

		text-transform: none;

		&--offset {
			bottom: t-rem(104px);
		}
	}

	&__compare {
		position: absolute;
		left: 50%;
		bottom: t-rem(16px);
		transform: translateX(-50%);
		z-index: 1;

		width: t-rem(340px);
		padding: t-spacing(md) 0;

		> a {
			text-decoration: none;
		}

		&__button.MuiButton-root {
			border-radius: t-rem(24px);

			background-color: $color-purple4;
			color: $color-white;

			text-transform: none;

			&:hover {
				background-color: $color-purple1;
			}
		}
	}
}

.c-modal.c-modal--product-search {
	.MuiPaper-root {
		position: fixed;
		top: 0;
		left: 0;

		width: 100vw;
		height: 100vh;
		max-width: none;
		max-height: none;
		margin: 0;
		border: none;
	}

	.c-modal__header {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		text-align: center;

		position: relative;

		min-height: none;
		max-width: t-rem(784px);
		margin: auto;
		padding: 0;

		.MuiIconButton-root {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: t-spacing(md);

			background: none;

			svg {
				fill: $color-purple2;
			}
		}
	}

	.c-modal__content {
		border-top: 1px solid transparentize($color-grey4, 0.5);

		.c-modal__title {
			max-width: t-rem(344px);
			margin: t-rem(20px) auto 0;

			color: $color-black;

			font-size: t-rem(12px);
			font-weight: 400;
			line-height: 1.33;
		}

		.c-modal__textfield {
			max-width: t-rem(344px);
			margin: t-rem(5px) auto 0;
		}
	}

	.c-modal__ctas {
		max-width: t-rem(344px);
		margin: t-rem(10px) auto 0;
	}
}

.c-product-search-form{
	padding: 0.9375rem 0.9375rem 0.9375rem;
    position: sticky;
    z-index: 200;
    top: 0;
    background-color: white;
}


.featured{
	.c-product-card{
		border: solid 3px #6200ea;
    	box-sizing: border-box;
	}
}