.chatbot {
    padding: 2rem 1rem 0;

    &__avatar {
        width: 224px;
        margin: 0 auto;
        margin-bottom: 0;
    }

    &__items {
        padding: 22px 1px 0 0;
        border-radius: 12px;
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);
        box-shadow: 0 20px 40px 0 rgba(179, 136, 255, 0.12);
        background-color: rgba(255, 255, 255, 0.88);
		font-family: 'Nunito';
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;

        &-summary {
            padding: 2rem 1.5rem;
        }

        &-anchor {
            display: block;
            padding: 2rem 1.5rem;
            border-top: 1px solid #dee7ed;
            text-decoration: none;
            color: black;
            position: relative;

            & > img {
                width: 40px;
                position: absolute;
                top: 25px;
                right: 12px;
            }
        }
    }

    &__search-input {
        position: relative;

        & > input {
            border-radius: 24px;
            border: solid 2px #6200ea;
            background-color: white;
            padding: .75rem 1.25rem;
            display: block;
            width: 100%;
            padding-right: 40px;

            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            color: #51338b;
        }

        & > button {
            cursor: pointer;
            width: 40px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 2px;
            border: none;
            background-color: transparent;


            & > img {
                margin: 0 auto;
                display: block;
                width: 100%;
                user-select: none;
                pointer-events: none;
            }
        }
    }
}

.chatbot-eop {
    padding: 2rem 1rem 1rem;
    margin: 4rem auto 0;
    font-family: 'Nunito';

    & &__wrapper {
        padding: 2rem 1rem 1rem;
        border-radius: 12px;
        box-shadow: 0 20px 40px 0 rgba(179, 136, 255, 0.12);
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;    
        position: relative;
    }

    &__avatar {
        position: absolute;
        top: -30px;
        width: 60px;
    }

    &__title {
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        margin: .35rem 0;
    }

    &__description,
    &__footnotes {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: center;
    }

    &__footnotes {
        margin-bottom: 1rem;
    }

    &__cta {
        display: block;
        border-radius: 24px;
        background-color: #6200ea;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: white;
        padding: 1rem 0;
        border: none;
        width: 100%;
        text-decoration: none;
    }
}

.chatbot-header {
    background-color: white;
    border-bottom: 1px solid #dee7ed;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &__title {
        font-family: 'Nunito';
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        flex-grow: 1;
    }

    &__previous,
    &__close {
        width: 48px;
    }
}

.chatbot-intro {
    font-family: 'Nunito';
    padding: 1.5rem 1rem;

    &__avatar {
        margin: 0 auto 1rem;
        width: 140px;
    }

    &__title {
        padding: 0 1.5rem;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
    }

    &__subtitle {
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: center;
        margin-top: 1rem;
    }
}

.chatbot-container {
    padding: 1rem;
}

.chatbot-queries {
    padding: 1rem;
    font-family: 'Nunito';
    margin-bottom: -50px;

    &__title {
        border-radius: 12px 12px 0 0;
        text-align: center;
        color: white;
        background-color: #51338b;
        padding: .5rem 1rem;
        backdrop-filter: blur(4px);
        box-shadow: 0 20px 40px 0 rgba(179, 136, 255, 0.12);
    }

    &__item {
        display: block;
        background-color: white;
        padding: 2rem 5.125rem 2rem 1.5rem;
        backdrop-filter: blur(4px);
        box-shadow: 0 20px 40px 0 rgba(179, 136, 255, 0.12);
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-decoration: none;
        color: black;
        position: relative;
        width: 100%;
        text-align: left;
        border: none;
        outline: none;

        & > img {
            width: 40px;
            position: absolute;
            top: 25px;
            right: 12px;
        }

        &:last-child {
            border-radius: 0 0 12px 12px;
        }

        &:not(:last-child) {
            border-bottom: 1px solid #dee7ed;
        }
    }
}

html {
    &:not(.chatbot-mobile-opened) #chatbot-chat {
        // display: none;
    }
}

#chatbot-chat {
    &:not(.open) {
        // right: -100% !important;
    }
}