.regimen-intermittent {
    display: flex;
    flex-direction: column;

    position: relative;

    max-width: t-rem(800px);
    min-height: calc(100vh - #{t-rem(80px)});
    margin: auto;
    padding: 0 t-spacing(md);

    .c-page-header {
        background-color: transparent;
    }

    &__container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        max-width: t-rem(800px);
        margin: auto;

        @include t-respond-from(tablet) {
            justify-content: center;
        }
    }

    &__text {
        color: $color-black;

        font-size: t-rem(24px);
        line-height: t-rem(32px);

        &:nth-child(2) {
            margin-top: t-spacing(md);
            margin-bottom: t-rem(24px);
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include t-respond-from(desktop) {
            flex-direction: row;
        }

        .e-button {
            margin-top: t-rem(12px);
            padding: t-rem(16px);
    
            background-color: rgba(242, 239, 249, 0.4);
            color: $color-purple2;
            box-shadow: none;
    
            font-size: t-rem(16px);
            font-weight: 600;
            white-space: nowrap;
            line-height: 1;
            letter-spacing: normal;

            &:hover, &:focus {
                background-color: rgba(242, 239, 249, 0.8);
            }
        }
    }
}