.c-promo-links {
    $root: &;
    $itemHeight: 140px;
    $itemHeightLarge: 250px;
    
    padding-bottom: t-rem(100px);

    @include t-respond-from(tablet) {
        margin-top: t-rem(48px);
    }

    &__list {
        margin: 0;
        padding-left: 0;

        @include t-respond-from(tablet) {
            display: grid;
            grid-auto-columns: 1fr;
            grid-gap: t-rem(24px);
            grid-template-areas:
                "first first"
                "second third"
                "fourth fourth"
                "fifth fifth"
                "sixth sixth";
        }
    }

    &__feedback {
        grid-area: sixth;
        min-height: $itemHeight;
        border-radius: t-rem(12px);
        padding: 20px 0;

        background-color: #FFFFFF;
        box-shadow: 0 t-rem(20px) t-rem(40px) 0 rgba(179, 136, 255, 0.12);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        p{
            padding: t-spacing(sm) t-spacing(lg);
        }
        a{
            margin: 0 t-spacing(lg);
            background-color: #5c17ec;
            color: white;
            text-decoration: none;
            height: 48px;
            display: block;
            padding: 0 t-spacing(lg);
            border-radius: 24px;
            line-height: 48px;
            text-align: center;
        }
        .c-promo-links__link-title{
            max-width: 100% !important;
        }
        div:first-of-type{
            flex-grow: 2;
        }
        @media (max-width: 767px) {
            display: block;
        }

    }

    &__item {
        position: relative;

        overflow: hidden;
        width: 100%;
        margin-top: t-spacing(lg);

        box-shadow: 0 t-rem(20px) t-rem(40px) 0 transparentize($color-purple8, 0.88);

        $labels: (first, second, third, fourth, fifth);

        @for $index from 1 through length($labels) {
            &:nth-child(#{$index}) {
                grid-area: nth($labels, $index);
            }
        }

        // Sim callout
        &:nth-child(4) {
            .c-promo-links__link {
                background: #1f2b68;
                
                h2 {
                    color: white;
                }
            }

            &::after {
                background-image: url('../../images/quarter-oval-2.png');
            }

            display: none;
        }

        // Banner callout
        &:nth-child(5) {
            .image--homeBanner {
                display: none;

                &--mobile {
                    display: block;
                }

                @include t-respond-from(tablet) {
                    display: block;

                    &--mobile {
                        display: none;
                    }
                }
            }
        }

        &:after {
            content: '';
            display: block;

            position: absolute;
            top: 100%;
            left: 100%;
            transform: translate(-100%, -100%);

            width: t-rem(106px);
            height: t-rem(103px);
            border-bottom-right-radius: t-rem(12px);

            background-image: url('../../images/quarter-oval.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }

        &--first:after {
            display: none;
        }

        &--first {
            margin-top: t-spacing(md);

            @include t-respond-from(tablet) {
                margin-top: t-rem(48px);
            }
        }

        @include t-respond-from(tablet) {
            margin-top: 0;
        }
    }

    #{$root} &__image {
        display: block;

        position: relative;
        z-index: 1;

        overflow: hidden;
        width: t-rem(100px);
        height: t-rem(100px);
        margin-right: t-spacing(lg);
        border-radius: 50%;

        img {
            display: block;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    #{$root} #{$root}__item--first &__image {
        @include t-respond-from(tablet) {
            width: t-rem(160px);
            height: t-rem(160px);
            margin-right: 0;
        }
    }

    &__link {
        display: flex;
        justify-content: space-between;
        align-items: center;

        position: relative;
        
        overflow: hidden;
        min-height: $itemHeight;
        border-radius: t-rem(12px);

        background-color: #FFFFFF;
        box-shadow: 0 t-rem(20px) t-rem(40px) 0 rgba(179, 136, 255, 0.12);

        text-decoration: none;

        transition: box-shadow ease-in-out 0.5s;

        @include t-respond-from('tablet') {
            min-height: t-rem(120px);
        }

        &:hover {
            @include t-box-shadow(10px, $color-purple-tr2);
        }

        &--first {
            border: solid 1px transparentize($color-purple8, 0.6);
        }

        #{$root} &-title {
            @include t-typography(h1);

            position: relative;
            z-index: t-layer('low');
                
            max-width: 55%; // force text to wrap
            padding: t-spacing(sm) t-spacing(lg);

            color: black;

            font-size: t-rem(18px);
            font-weight: 600;
            line-height: t-rem(24px);
        }

        @include t-respond-from('tablet') {
            #{$root} #{$root}__item--first &-title {
                padding: t-rem(16px) 0;
                
                font-size: t-rem(32px);
                font-weight: 400;
                white-space: nowrap;
            }
        }
    }

    .c-promo-links__wrapper {
        border: solid 1px transparentize($color-purple8, 0.6);
        border-radius: t-rem(12px);

        background-color: white;
        
        .c-promo-links__top {
            display: flex;
            align-items: center;
            justify-content: space-between;

            position: relative;

            padding-top: t-spacing(md);
            padding-bottom: t-spacing(md);

            &:after {
                content: '';
                display: block;
    
                position: absolute;
                top: 100%;
                left: 100%;
                transform: translate(-100%, -100%);
    
                width: t-rem(106px);
                height: t-rem(103px);
    
                background-image: url('../../images/quarter-oval.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }

            @include t-respond-from('tablet') {
                align-items: flex-start;
                padding: t-rem(12px) t-rem(24px);
            }
        }
        
        .c-promo-links__bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;

            padding: t-spacing(lg) + 4px;
            border-top: solid 1px transparentize($color-purple8, 0.6);

            @include t-respond-from('tablet') {
                position: absolute;
                bottom: t-rem(32px);

                padding: 0;
                border-top: none;
                left: t-rem(24px);

                width: t-rem(320px);
            }

            & > * {
                margin-right: t-rem(16px);

                &:last-child {
                    margin-right: 0;
                }
            }

            .e-button {
                background-color: $color-purple6;

                font-weight: 600;
                text-decoration: none;
                letter-spacing: normal;

                transition: background-color 250ms;

                &:hover {
                    background-color: darken($color-purple6, 10%);
                }
            }
        }
    }
}