.calculator-selector {
    .c-dropdown {
        .e-select {
            border: none;
            border-radius: 0;
            
            color: $color-black;
            box-shadow: none;

            &__select {
                border: solid t-rem(2px) $color-purple7;
                
                &:focus {
                    border: solid t-rem(2px) $color-purple6;
                    outline: none;
                }
            }

            &:after {
                display: none;
            }

            & > svg {
                fill: black;
            }
        }
    }
}