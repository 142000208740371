.e-select {
  @include t-box-shadow;

  background-color: $color-white;
  border: 1px solid $color-purple5;
  border-radius: $global-border-radius;
  overflow: hidden;
  position: relative;

  &::after {
    background: linear-gradient(270deg, $color-purple2, $color-purple4);
    content: '';
    display: block;
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 44px;
  }

  &__arrow {
    color: $color-white;
    pointer-events: none;
    position: absolute;
    right: t-spacing(sm);
    top: 50%;
    transform: translateY(-50%);
    z-index: t-layer('low');
  }

  &__select {
    @include t-typography(tab);

    appearance: none;
    background-color: $color-white;
    border: none;
    line-height: 1rem;
    padding: t-spacing(md);
    width: 100%;
  }
}
