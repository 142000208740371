.c-height-picker {
  &__controls {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: t-spacing(sm);
    }
  }

  &__dropdown {
    select,
    & {
      width: 100%;
    }

    &:not(:last-child) {
      margin-right: t-spacing(sm);
    }
  }

  &__label {
    @include t-typography(h3);

    display: block;
    margin-bottom: t-spacing(sm);
  }
}
