.c-language-selector {
    display: flex;

    position: absolute;
    top: t-spacing(lg);
    right: t-spacing(lg);

    &__dropdown {
        @include t-typography(tab);

        appearance: none;
        background-color: transparent;
        border: none;
        color: $color-white;
        padding-right: t-spacing(lg);

        option {
          color: $color-grey-tr1;
        }
    }

    &--static {
        position: static;
    }

    @include t-respond-from(tablet) {
        top: t-rem(40px);
        right: 0;
    }

    .MuiSvgIcon-root{
        pointer-events: none;
        position: absolute;
        right: 0;
    }
}

.c-language-selector__icon{
    $size: t-rem(22px);

    display: block;
    width: $size;
    height: $size;
    border-radius: 50%;
    margin-right: t-rem(5px);
    
    background-size: cover;

    &--roi {
        background-image: url('../../images/ireland-flag.svg');
    }

    &--uk {
        background-image: url('../../images/united-kingdom-flag.png');
    }
}