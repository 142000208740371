.weight-text-field {
    margin-top: t-spacing(xl) !important;
    
    &__header {
        display: flex;
        align-items: center;
    
        margin: t-rem(24px) 0;
    
        .c-field-header {
            text-align: left;
            flex-grow: 1;
    
            margin: 0;
    
            &__label {
                margin: 0 !important;
            }
        }
    }

    &__imperial {
        display: flex;

        & > * {
            &:first-child {
                margin-right: t-spacing(sm);
            }
            
            &:last-child {
                margin-left: t-spacing(sm);
            }
        }
    }
    
    svg {
        margin-left: t-rem(6px);
    }
}