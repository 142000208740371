.main--regimen-report > section {
    background-color: $color-white;
}

.c-report {
    &__sections {
        @include t-respond-from(desktop) {
            padding: 0 t-rem(34px);
        }

        .c-field-header {
            margin-bottom: 0;
            padding: 0 t-rem(24px);
            border-bottom: solid 1px transparentize($color-grey6, 0.6);

            @include t-respond-from(desktop) {
                padding: 0;
            }
            
            &__label {
                margin: t-rem(28px) 0;

                font-weight: normal;
            }
        }

        .c-accordion {
            box-shadow: none;
            
            .c-accordion__header {
                padding: t-rem(32px) t-rem(24px);
                border-bottom: solid 1px transparentize($color-grey6, 0.6);
    
                .c-accordion__title {
                    color: $color-black;
    
                    font-size: t-rem(18px);
                    font-weight: 600;
                }
            }

            .c-accordion__list-item {
                padding: t-rem(24px);
                border-bottom: solid 1px transparentize($color-grey6, 0.6);
            }
        }

        & + .c-step-form__ctas {
            padding: 0 t-rem(15px);

            > * {
                box-shadow: none;

                font-weight: 600;
                letter-spacing: normal;

                &:first-child {
                    border-radius: none;

                    background-color: transparent;
                    color: $color-purple1;

                    &:hover {
                        color: $color-purple4;
                    }
                }

                &:last-child {
                    background-color: $color-purple1;
                    color: $color-white;

                    &:hover {
                        background-color: $color-purple4;
                    }
                }
            }
        }
    }
}