.c-page-footer {
	display: flex;
	flex-direction: column;
	justify-content: center;

	position: fixed;
	bottom: t-rem(56px);
	left: 0;
	right: 0;
	
	width: 100%;
}
