.c-product-header-comparison {
	display: flex;
	justify-content: center;
	align-items: center;

	h2 {
		color: black;
	}

	svg {
		color: $color-purple2;
	}
}