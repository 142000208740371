.c-instructions {
    min-height: calc(100vh - #{t-rem(187px)});
    padding: 0 t-rem(34px);
    border-radius: t-rem(12px);

    background-color: $color-white;

    &__icon {
        display: block;
        
        $size: t-rem(40px);
        height: $size;
        width: $size;
        min-width: $size;
        margin: 0 t-spacing(md) 0 0;

        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        &--ios-safari-plus {
            background-image: url('../../images/add-to-homescreen/ios-safari-plus.svg');
        }

        &--ios-safari-share {
            background-image: url('../../images/add-to-homescreen/ios-safari-share.svg');
        }

        &--windows-chrome-plus {
            background-image: url('../../images/add-to-homescreen/windows-chrome-plus.png');
        }

        &--windows-chrome-settings {
            background-image: url('../../images/add-to-homescreen/windows-chrome-settings.png');
        }

        &--windows-edge-chromium-plus {
            background-image: url('../../images/add-to-homescreen/windows-edge-chromium-plus.png');
        }

        &--windows-edge-chromium-settings {
            background-image: url('../../images/add-to-homescreen/windows-edge-chromium-settings.png');
        }

        &--edge-chromium-permissions {
            background-image: url('../../images/add-to-homescreen/edge-chromium-permissions.png');
        }

        &--edge-chromium-padlock {
            background-image: url('../../images/add-to-homescreen/edge-chromium-padlock.png');
        }

        &--chrome-info {
            background-image: url('../../images/add-to-homescreen/chrome-info.png');
        }

        &--chrome-padlock {
            background-image: url('../../images/add-to-homescreen/chrome-padlock.png');
        }

        &--firefox-arrow {
            background-image: url('../../images/add-to-homescreen/firefox-arrow.png');
        }

        &--firefox-padlock {
            background-image: url('../../images/add-to-homescreen/firefox-padlock.png');
        }

        &--edge-plus {
            background-image: url('../../images/add-to-homescreen/edge-plus.png');
        }

        &--edge-padlock {
            background-image: url('../../images/add-to-homescreen/edge-padlock.png');
        }
    }

    &__list {
        margin: 0;
        padding: 0;
    }

    &__item {
        display: flex;
        align-items: center;

        margin-bottom: 0;
        padding: t-rem(34px) 0;
        border-radius: 0;
        border-bottom: solid 1px transparentize($color-grey4, 0.5);
        
        background-color: $color-white;
        color: $color-grey1;

        h3 {
            padding-left: t-rem(15px);

            color: $color-black;
        }
    }

    &__text {
        @include t-typography(h2);
    }
}