html {
    min-height: 100%;

    background-color: $color-white;

    font-size: $global-font-size;
    font-family: $font-family-default;
    line-height: $global-line-height;
}

/* Set core body defaults */
body {
    overflow-y: auto;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

main {
    display: block;

    position: relative;
 
    max-width: none;
    width: 100%;
    margin: 0 auto;
    padding: 0 0 calc(#{t-spacing(md)} + #{t-rem(56px)});

    // background-image: linear-gradient(210deg, rgba(48, 79, 254, 0.2) 0%, rgba(98, 0, 234, 0.) 20%, rgba(255, 255, 255, 0) 50%);

    background: url('../../images/gradient.png') no-repeat right top / 100% 100vh;

    & > * {
        box-sizing: border-box;
        max-width: t-rem(784px);
        margin: auto;
    }
}

h1 {
    @include t-typography(h1, bold);
}

h2 {
    @include t-typography(h2, bold);
}

h3 {
    @include t-typography(h3, bold);
}

p {
    @include t-typography(body);

    a {
        color: $color-purple1;
        overflow-wrap: break-word;

        &:visited,
        &:link {
            text-decoration: underline;
        }

        &:hover {
            text-decoration: none;
        }
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0;
    max-width: 100%;
    width: 100%;
}

tr {
    border-bottom: t-rem(1px) solid $color-grey5;
}

td {
    padding: t-spacing(sm);

    &:last-child {
        text-align: right;
    }
}

.u-content-wrapper {
    @include t-owl;
}