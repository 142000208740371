.c-modal {
    &__content {
        padding: t-spacing(md);
    }

    &__inner{
        margin: 0 auto;
        background-color: white;
        padding: t-rem(10px) t-rem(40px) t-rem(20px);
        margin-top: t-rem(20px);
        border-radius: t-rem(20px);
    }

    &__ctas {
        padding: 0 t-spacing(md) t-spacing(md);
    }

    &__error {
        margin-top: t-spacing(sm);
        padding: t-spacing(md);
    }

    &__header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        min-height: 59px;
        padding: t-spacing(md) t-spacing(md) 0;
        width: 100%;
    }

    &__message {
        margin-bottom: t-spacing(md);
    }

    &__textfield {
        align-items: center;
        display: flex;
        max-width: 70%;

        &--full-width {
            max-width: 100%;
        }
    }

    &__unit {
        @include t-typography(h3);

        margin-left: t-spacing(sm);
    }

    &__validation-message {
        color: $color-purple2;
        margin-top: t-spacing(sm);
    }

    &__description {
        margin-bottom: t-spacing(sm);
    }

    &--email-report {
        .MuiDialog-scrollPaper {
            align-items: flex-start;

            height: 100%;

            background-color: $color-white;
        }

        .MuiDialog-paper.MuiPaper-root {
            max-width: none;
            width: 100%;
            border: none;
            margin: 0;
            height: 100%;
            box-shadow: none;
        }

        .c-modal__header {
            text-align: center;

            position: relative;

            max-width: t-rem(784px);
            margin: auto;
            padding: 0;

            /*
            &:after {
                display: block;
                content: '';

                position: fixed;
                top: t-rem(84px);
                left: 0;

                width: 100%;
                height: 1px;

                background-color: $color-grey4;
            }
            */

            h2 {
                text-align: center;
                
                width: 100%;
                padding: t-rem(42px) t-rem(15px) t-rem(13px);

                color: $color-black;

                font-size: t-rem(18px);
            }

            button {
                position: absolute;
                bottom: t-rem(15px);
                right: 0;

                width: t-rem(29px);
                height: t-rem(29px);
                padding: 0;

                background: transparent;

                svg {
                    width: t-rem(30px);
                    height: t-rem(30px);

                    fill: $color-purple1;
                }
            }
        }

        .c-modal__content {
            padding-top: t-rem(57px);

            input::placeholder {
                color: transparentize($color-black, 0.5);
            }
            
            fieldset {
                border: solid 1px $color-grey4;
            }
        }

        .c-modal__content,
        .c-modal__ctas {
            max-width: t-rem(344px);
            margin: auto;
        }
    }

    .c-product {
        padding: t-spacing(md);
    }

    .MuiPaper-root {
        border: 1px solid $color-purple1;
        background-color: rgba(80, 80, 80, 0.5);
    }

    .MuiDialog-container {
        background-color: transparent;
    }

    .MuiIconButton-root {
        @include t-brand-gradient;

        color: $color-white;
        margin-left: t-spacing(md);
        padding: t-spacing(sm);
        

        &:first-child {
            margin-left: auto;
        }
    }

    .c-text-field .MuiInputBase-root {
        border-color: $color-grey6;
    }

    .c-modal__ctas {
        padding: 0;

        .e-button.MuiButton-root {
            //background-color: rgba(242, 239, 249, 0.4);
            //color: $color-purple2;
            box-shadow: none;

            font-size: t-rem(16px);
            letter-spacing: normal;
        }
        .Mui-disabled{
            color: $color-purple2;
        }
    }
}

.c-modal--product-search{
    .c-modal__header{
        button{
            right: t-rem(-25px) !important;
        }
    }
}