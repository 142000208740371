.c-slider--pal-dit {
    margin: 0 t-rem(-15px);
    border-top: 1px solid $color-grey4;

    .c-field-header {
        padding: 0 t-rem(15px);
    }

    .c-slider__info {
        padding: t-spacing(md) t-rem(15px);

        background-image: linear-gradient(
            to top right,
            rgba(255, 255, 255, 0.0) 0% 40%,
            rgba(98, 0, 234, 0.1),
        );

        font-size: t-rem(42px);
        line-height: 1.29;

        small {
            display: block;

            font-size: t-rem(16px);
        }
    }

    .c-slider__label {
        padding: t-spacing(sm) t-rem(15px) 0;
        
        font-weight: 600;
    }

    .c-slider__slider {
        display: block;

        padding: 0 t-rem(15px);

        &-labels {
            display: flex;
            justify-content: space-between;
            
            & > span:first-child {
                justify-content: flex-start;
            }
            
            & > span:last-child {
                justify-content: flex-end;
            }
        }
    }
}