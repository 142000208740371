.c-filter-list {
    display: none;

    position: fixed;
    top: 0;
    left: 0;
    z-index: t-layer('highest');
    transform: translate3d(0, 0, 0);

    overflow: hidden;
    width: 100%;
    height: 100%;

    background: $color-white;

    &--active {
        display: block;
    }

    &__title {
        text-align: center;

        width: 100%;

        @include t-typography(button, semi-bold);
    }

    &__close.MuiButton-root {
        display: inline-flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        right: 0;

        min-width: 0;
        margin-right: #{t-spacing(sm)};
        padding: t-spacing(sm);
        border: none;
        border-radius: 100%;

        background: none;
        
        cursor: pointer;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: t-spacing(md) 0 t-spacing(md);
        border-bottom: 1px solid $color-grey6;
    }

    &__inner {
        display: flex;
        flex-direction: column;

        position: relative;

        width: 100%;
        height: 100%;
    }

    &__header,
    &__submit {
        display: flex;
        flex: 1;

        min-height: 70px;
    }

    &__list-wrapper {
        display: flex;
        flex: 10 auto;

        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        padding: t-spacing(md);
        margin-bottom: t-spacing(md);
    }

    &__list {
        &--full {
            max-width: 100%;
        }

        &--notifications {
            margin-bottom: t-spacing(md);
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        .MuiFormControlLabel-root {
            color: $color-purple1;
            width: 100%;
        }

        .MuiTypography-root {
            font-family: $font-family-default;
        }

        .MuiCheckbox-colorSecondary+span {
            color: black !important;
        }

        .MuiCheckbox-colorSecondary.Mui-checked {
            color: $color-purple2;
        }
    }

    &__list--checkbox {
        border-color: $color-purple2 !important;
    }

    &__section {
        margin: 0 t-spacing(md);
        padding: t-spacing(md) 0 t-spacing(md);
        border-bottom: 1px solid $color-grey4;

    }

    &__section-description {
        margin: t-spacing(xs) 0;
    }

    &__section-title {
        color: $color-purple1;
    }

    &__select-all {
        margin-left: t-spacing(sm);
        padding: 0;
        border: none;

        background: none;
        color: $color-purple2;
        
        font-weight: normal;
        
        cursor: pointer;
    }

    &__submit {
        text-align: center;

        padding: 0 t-spacing(md) t-spacing(md) t-spacing(md);

        span {
            font-size: t-rem(16px) !important;
        }
    }

    &__submit {
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
    }

    &__submit--clear {
        margin-right: t-spacing(md) !important;

        background-color: rgba(202, 188, 230, 0.5) !important;
        color: $color-purple1 !important;

        opacity: 0.5 !important;
    }

    &__submit--show {
        margin-left: t-spacing(md) !important;
    }

    .accordion {
        border-bottom: solid 1px $color-grey6;

        .accordion__label {
            padding: t-rem(40px) 0;
            
            font-size: t-rem(18px);
        }
    }


    &__list {
        > ul {
            > li {
            }

            ul {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                
                li {
                    padding-right: t-spacing(md);
                }
            }
        }

        &:not(.c-filter-list__list--full) {
            width: 100%;
            max-width: 784px;
            margin: 0 auto t-spacing(lg);
        }
    }
}