.regimen-patient-details {
    background-color: $color-white;

    &__bmi {
        display: flex;
        align-items: center;

        padding: t-spacing(lg) t-rem(32px);

        @media (max-width: 768px){
            padding: t-spacing(lg) t-rem(16px);
        }

        &__left {
            flex-grow: 1;
            text-align: left;

            p {
                color: $color-black;
                
                &:first-child {
                    font-size: t-rem(50px);
                    line-height: 1;
                }
    
                &:last-child {
                    font-size: t-rem(14px);
                }

                sup{
                    line-height: 0;
                }
            }

            @media (max-width: 768px){
                margin-right: 2rem;

                p { 
                    &:first-child {
                        font-size: t-rem(32px);
                        line-height: 1;
                    }
                }
            }

        }
    }

    .c-step-form {
        text-align: center;

        padding-top: t-spacing(md);
        padding-left: t-rem(15px);
        padding-right: t-rem(15px);

        background-color: white;
    }

    .c-radio-list__option {

        height: t-rem(48px);

        background-color: $color-purple7;

        &:before,
        & > span:first-child {
            display: none;
        }

        &--active {
            background-color: $color-purple6;
        }

        .MuiTypography-root {
            margin: auto;
        }
    }

    fieldset,
    .c-field-header {
        text-align: center;
    }

    .c-radio-list__label,
    .c-field-header__label {
        margin: t-spacing(lg) auto;

        color: black;

        font-size: t-rem(18px);
        line-height: t-rem(24px);
    }

    .c-tabs .MuiTabs-root {
        box-shadow: none;
    }
}