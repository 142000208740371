.c-page-footer-fixed {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 2;

	width: 100%;
	padding: t-spacing(xl) t-spacing(md) t-spacing(md);

	background-color: $color-white;
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);
	
	opacity: 0;
	-webkit-animation: fadeOut 0.1s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadeOut 0.1s; /* Firefox < 16 */
	-ms-animation: fadeOut 0.1s; /* Internet Explorer */
	-o-animation: fadeOut 0.1s; /* Opera < 12.1 */
	animation: fadeOut 0.1s normal;

	&__show {
		-webkit-animation: fadein 0.1s; /* Safari, Chrome and Opera > 12.1 */
		-moz-animation: fadein 0.1s; /* Firefox < 16 */
		-ms-animation: fadein 0.1s; /* Internet Explorer */
		-o-animation: fadein 0.1s; /* Opera < 12.1 */
		animation: fadein 0.1s normal;
		opacity: 1;
	}
}

@keyframes fadein {
	from {
		transform: translate3d(0, -80px, 0);

		opacity: 0.1;
	}

	to {
		transform: translate3d(0, 0px, 0);

		opacity: 1;
	}
}

@keyframes fadeOut {
	from {
		transform: translate3d(0, 0px, 0);

		opacity: 1;
	}

	to {
		transform: translate3d(0, -80px, 0);

		opacity: 0;
	}
}
