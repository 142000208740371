.c-nutrient-table {

  display: flex;
  flex-direction: row;

  margin: 10px 0;
  table,
  thead,
  tbody,
  tr,
  &__item,
  & {
    width: 100%;
  }

  tr {
    border: none;
  }

  td {
    text-align: left;
  }

  th,
  &__nutrient-name {
    padding: 0 0 t-spacing(xs);
  }

  &__item {
    border-bottom: 1px solid $color-grey5;
    display: block;
    margin-bottom: t-spacing(md);
    padding: 0 0 t-spacing(md);

    &-heading {
      color: rgba(0, 0, 0, 0.5);;
      min-height: 21px;
      &-unit {
        text-transform: none;
      }
    }    
  }

  &__nutrient {
    display: block;
    margin-right: t-spacing(md);
    padding: 0;
    width: 50%;

    &:last-child {
      margin:0;
    }
  }

  &__nutrient-wrapper {
    display: flex;
  }

  &__section-title {
    @include t-typography(h1, bold);
    margin-bottom: t-spacing(md);
    text-align: left;
  }
}

