.c-product-card {
	$root: &;

	display: flex;
	flex-direction: column;

	position: relative;

	overflow: hidden;
	height: 100%;
	border: solid 1px transparentize($color-purple8, 0.8);
	border-radius: t-rem(8px);
	
	text-decoration: none;

	@include t-respond-from('desktop') {
		grid-template-columns: 200px 1fr;
	}

	&--mini {
		grid-template-columns: 80px 1fr;
		grid-template-areas:
			'image content'
			'image content'
			'image link';

		@include t-respond-from('desktop') {
			grid-template-columns: 150px 1fr;
		}

		#{$root}__image {
			padding: t-spacing(sm) t-spacing(md);
		}
	}

	&--compendium {
		#{$root}__link {
			padding: t-spacing(sm) t-spacing(md);
			text-align: right;
		}
	}

	&__content {
		flex-grow: 1;
		flex-direction: column;

		padding: t-rem(13px) t-rem(8px) 0;
	}

	&__top {
		border-bottom: solid 1px transparentize($color-purple8, 0.8);
	}

	&__image {
		display: flex;
		align-items: center;
		justify-items: center;
		flex-direction: column;
		
		position: relative;

		height: t-rem(170px);
		margin: auto;
		padding: t-rem(34px) 0;

		@include t-respond-from('desktop') {
			padding: t-spacing(lg) t-spacing(md);
		}

		.c-product-card__image--background {
			display: flex;

			position: absolute;
			z-index: -1;

			width: 100%;
			height: 100%;
			margin: t-rem(-15px);
		}

		img {
			object-fit: contain;
			width: t-rem(150px);
			max-height: 100%;
			margin: 0 auto;
		}
	}

	&__links {
		@include t-typography(details, bold);

		display: flex;
		grid-area: link;
		
		overflow: hidden;
		padding: t-rem(13px) t-rem(8px) t-rem(8px);
		
		color: $color-purple4;
		
		text-align: right;
		text-transform: uppercase;

		& > * {
			margin-right: t-rem(10px);

			&:last-child {
				margin-right: 0;
			}
		}
	}

	&__compare {
		@include t-typography(tab, normal);

		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;

		overflow: hidden;
		width: 50%;
		padding: t-spacing(sm) t-spacing(sm);
		border-radius: t-rem(16px);
		
		background-color: transparentize(#cabce6, 0.9);
		color: $color-purple2;
		
		font-weight: 600;
		text-align: right;
		text-transform: none;

		appearance: none;

		transition: background-color 250ms, color 250ms;

		&:hover {
			background-color: $color-purple1;
			color: $color-white;
		}
	}

	&__compare-selected {
		background-color: $color-purple4;
		color: $color-white;
	}

	&__link {
		@include t-typography(tab, normal);

		display: flex;
		flex: 1;
		justify-content: center;
		align-items: center;
		text-align: center;

		padding: t-spacing(sm) t-spacing(xs);
		border: none;
		border-radius: t-rem(16px);

		background-color: transparentize(#cabce6, 0.9);
		color: $color-purple2;
		
		font-weight: 600;
		text-transform: none;
		text-decoration: none;

		cursor: pointer;

		transition: background-color 250ms, color 250ms;

		&:hover, &--active {
			background-color: $color-purple4;
			color: $color-white;
		}
	}

	&__meta {
		@include t-typography(details, normal);

		display: block;

		color: $color-grey2;
	}

	&__skip {
		position: absolute;
		right: -99999px;
		top: -99999px;

		height: 0;
		width: 0;
		
		opacity: 0;

		&:focus {
			top: t-spacing(sm);
			right: t-spacing(sm);

			width: auto;
			height: auto;
			padding: t-spacing(md);
			
			background-color: $color-white;
			box-shadow: 0 0 5px $color-purple4;
			
			opacity: 1;
		}
	}

	&__title {
		@include t-typography(h3, normal);

		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;

		overflow: hidden;

		font-size: t-rem(16px);
		line-height: t-rem(21px);
	}

	&__description {
		display: -webkit-box;
		-webkit-line-clamp: 6;
		-webkit-box-orient: vertical;

		overflow: hidden;
		min-height: 96px !important;

		color: $color-black;

		font-size: t-rem(12px);
	}

	&__title,
	&__meta {
		text-transform: capitalize;
	}

	&__nutrition {
		opacity: 0;
		$nutritionIconSize: t-rem(24px);

		position: absolute;
		top: t-rem(8px);
		right: t-rem(8px);

		width: $nutritionIconSize;
		height: $nutritionIconSize;
		border-radius: 50%;

		background-color: transparentize(#cabce6, 0.9);

		&:hover {
			background-color: $color-purple7;
		}

		& > * {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			#{$root}__image & {
				width: t-rem(12px);
			}
		}
	}

	&__details {
		@include t-typography(tab, normal);

		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
		text-align: right;

		overflow: hidden;
		width: 50%;
		padding: t-spacing(xs) t-spacing(sm);
		border-radius: t-rem(16px);
		
		background-color: transparentize(#cabce6, 0.9);
		color: $color-purple2;
		
		font-weight: 600;
		text-transform: none;
		text-decoration: none;

		appearance: none;

		transition: background-color 250ms, color 250ms;

		&:hover {
			background-color: $color-purple1;
			color: $color-white;
		}
	}
}

.is-ie {
	.c-product-card {
		display: flex;

		&__image {
			flex: 1;

			img {
				max-height: unset;
				width: 100%;
			}
		}

		&__content {
			flex: 3;
		}

		&__links {
			flex: 3;
		}
	}
}

.c-product-card__featured{
	position: absolute;
	top: 10px;
	left: 0;
	background-color: #6200ea;
	color: white;
	padding: 5px 14px 5px 8px;
	font-family: 'Nunito';
	font-size: 12px;
	line-height: 14px;
	border-radius: 0 14px 14px 0;
	z-index: 10;
}
