.c-slider {
  $sliderLineHeight: 4px;

  > * ~ *,
  .MuiTextField-root {
    margin-top: t-spacing(sm);
  }

  .MuiTextField-root {
    background-color: $color-purple-tr1;
    color: $color-purple1;
    width: 100%;
  }

  .MuiInputBase-root {
    @include t-typography(h3);

    font-family: $font-family-default;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-notchedOutline {
    border-color: $color-purple-tr2;
  }

  &__slider {
    align-items: center;
    display: flex;
  }

  &__slider-label {
    @include t-typography(details, bold);

    align-items: center;
    color: $color-purple2;
    display: flex;
    height: 24px;
    justify-content: center;
    width: 32px;

    &:first-child {
      margin-right: t-spacing(md);
    }

    &:last-child {
      margin-left: t-spacing(md);
    }
  }

  .MuiSlider-root {
    color: $color-purple4;
  }

  .MuiSlider-thumb {
    $size: 19px;

    height: $size;
    width: $size;
    margin-left: -$size/2;
    margin-top: -$size/2 + 1;

    .MuiSlider-valueLabel {
      @include t-typography(details, normal);

       font-family: $font-family-default;
      left: calc(-50% + 3px);
    }
  }

  .MuiSlider-root,
  .MuiSlider-rail,
  .MuiSlider-track {
    height: $sliderLineHeight;
  }

  .MuiSlider-rail {
    background-color: $color-grey6;
  }
}
