.c-country-selector {
  $root: &;
  $itemHeight: 140px;
  $itemHeightLarge: 230px;

  &__image {
    background-position: center;
    background-size: cover;
    display: block;
    height: 96px;
    overflow: hidden;
    position: relative;
    width: 100%;

    @include t-respond-from('tablet') {
      height: 148px;
    }

    @include t-respond-from('desktop') {
      height: 198px;
    }

    &--roi {
      background-image: url('../../images/ireland-flag-lg.svg');
    }

    &--uk {
      background-image: url('../../images/united-kingdom-flag-lg.svg');
    }
  }

  &__label {
    @include t-block;

    border: none;
    display: block;
    min-height: $itemHeight;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-align: left;
    text-decoration: none;
    width: 100%;

    @include t-respond-from('desktop') {
      min-height: $itemHeightLarge;
    }

    &-title {
      display: block;
      font-weight: normal;
      padding: t-spacing(sm) t-spacing(md);
    }

    input {
      left: -9999px;
      position: absolute;
      top: -9999px;
    }
  }

  &__list {
    display: grid;
    grid-gap: t-spacing(sm);
    grid-template-columns: 1fr 1fr;
    margin: 0;
    padding: 0;
  }

  &__title {
    margin-bottom: t-spacing(md);
  }
}

.is-ie {
  .c-country-selector {
    &__item {
      flex: 1;
      width: 50%;

      &:first-child {
        margin-right: t-spacing(md);
      }
    }

    &__list {
      display: flex;
      width: 100%;
    }
  }
}
