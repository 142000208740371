.c-slider--calory-summary {
    margin: 0 t-rem(-15px) !important;
    padding: t-spacing(md) t-rem(15px);

    background-image: linear-gradient(
        to top right,
        rgba(255, 255, 255, 0.0) 0% 40%,
        rgba(98, 0, 234, 0.1),
    );

    &__label {
        font-size: t-rem(16px);
        line-height: 1.5;
    }

    &__info {
        font-size: t-rem(42px);
        line-height: 1.29;

        small {
            font-size: t-rem(14px);
        }
    }
}