.e-basket {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: relative;

  &__count {
    $size: 16px;
    @include t-typography(details);

    background-color: red;
    border-radius: 100%;
    color: $color-white;
    height: $size;
    position: absolute;
    right: -#{$size/2};
    top: 0;
    width: $size;
  }
}