.c-product {
	$root: &;

	&__accordion{
		border-bottom: solid 1px #dee7ed;
		font-family: 'Nunito';
		font-size: 18px;
		font-weight: 500;
		padding: 4px 0;
		margin-bottom: 0px !important;
		display: flex;
		justify-content: space-between;
		align-items: center;

		div:nth-child(2) {
			font-size: 30px;
			color: #51338b;
			padding-right: 10px;
		}

		div:nth-child(1) {
			padding-left: 2px;
		}
	}

	&__values{
		padding-bottom: 0px !important;
	}

	&__codes {
		margin: 0;
		padding: 0;
	}

	&__cta {
		.MuiButton-root {
			margin: t-spacing(lg) 0 0;
		}
	}

	&__info-wrapper {
		display: grid;
		grid-auto-columns: max-content; //added
		grid-auto-flow: dense; // added
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 10px;
		row-gap: 24px;

		padding-bottom: t-spacing(lg);


		&--expandable{
			.c-product__info-item {
				max-height: 100px;
				overflow: hidden;
				box-sizing: border-box;
			}
		}

		&--accordion{
			display: none;
		}
	}

	&__info-item {
		flex: 1;

		p {
			display: -webkit-box;
			-webkit-line-clamp: 7;
			-webkit-box-orient: vertical;

			overflow: hidden;

			font-size: t-rem(12px);
			line-height: t-rem(16px);
		}
	}

	&__info-expand {
		p {
			-webkit-line-clamp: 100 !important;
			overflow: auto;
		}
		.c-product__info-item {
			max-height: fit-content !important;
		}
	}

	&__info-item-center {
		display: flex;
		justify-content: center;
	}

	&__title-wrapper {
		flex: 1;
		position: sticky;
		top: 62px;
		z-index: 2;
		background-color: white;
		padding-top: 10px;
	}
	&__title-mini {
		@include t-typography(body);
	}

	&__details {
		text-align: center;

		margin: 0;
		padding: t-spacing(lg) 0 0;
		border-bottom: 1px solid $color-grey4;

		ul {
			padding-left: 0;
			justify-content: flex-start;
			display: flex;
			flex-direction: column;
			align-items: center;
			list-style: none;
		}

		h3 {
			color: transparentize($color-black, 0.5);

			font-size: t-rem(16px);
			font-weight: normal;
		}
	}

	&__details-item {
		@include t-typography(tab);

		display: inline-flex;

		margin: 0 t-spacing(xs) t-spacing(sm) 0;
		padding: t-spacing(sm) t-spacing(md);
		border-radius: t-rem(50px);

		background-color: $color-purple-tr2;

		span {
			margin-right: t-spacing(xs);

			color: $color-purple1;

			font-weight: bold;
		}
	}

	&__images {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		position: relative;

		box-sizing: border-box;
		padding-bottom: 10px !important;
	}

	&__image {
		display: flex;
		justify-content: center;

		position: relative;

		box-sizing: border-box;
		width: 100%;
		height: t-rem(167px);
		margin-bottom: t-spacing(md);
		padding: t-spacing(lg);
		
		object-fit: contain;

		.c-product__image--background {
			display: flex;

			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;

			width: 100%;
			height: 100%;
			border: solid 1px $color-purple4;
			border-radius: t-rem(8px);

			opacity: 0.2;
		}

		.c-product__image--remove {
			position: absolute;
			top: t-rem(10px);
			right: t-rem(10px);
			z-index: 1;

			height: t-rem(34px) !important;
			width: t-rem(34px) !important;
			padding: t-spacing(xs);
			border-radius: 100%;

			background-color: $color-white;
		}

		img {
			z-index: 1;

			height: auto;
			max-width: 130px;

			object-fit: contain;
		}
	}

	&__list {
		display: grid;
		grid-gap: t-spacing(sm);
		grid-template-columns: 1fr;

		margin: t-spacing(sm) 0 0;
		padding: 0;

		@include t-respond-from('desktop') {
			//grid-template-columns: 1fr 1fr 1fr;
		}

		li {
			flex: 1;
		}

		&--double {
			grid-template-columns: repeat(2, 1fr);
		}

		&--allergens {
			display: grid;

			width: 100%;
			margin-top: 0;
			padding: 0;

			list-style: none;

			li {
				display: flex;
				align-items: center;
				
				margin-top: t-rem(10px);

				div {
					padding-left: t-rem(10px);
				}

				img {
					width: t-rem(24px);
				}
			}
		}

		&--nutrition {
			display: grid;

			width: 100%;
			margin-top: 0;
			padding: 0;

			list-style: none;

			li {
				display: flex;
				flex-direction: column;
				text-align: left;

				margin: 0 t-rem(5px);
				border-bottom: 1px solid #dee7ed;

				div {
					margin: t-rem(5px) 0;

					&:first-of-type {
						color: rgba(0, 0, 0, 0.5);
					}
				}
			}
		}
	}

	&__tab {
		padding: 0 t-rem(10px);

		> * {
			&:not(:last-child) {
				margin: 0 0 t-spacing(sm);
			}
		}

		h1 {
			color: $color-purple2;

			font-weight: normal;
		}
	}

	&__tab--single {
		.c-product__info-wrapper {
			grid-template-columns: repeat(1, 1fr) !important;
		}
	}

	&__tab--directionsforuse {
		h3 {
			text-align: left !important;
		}
	}

	&__text {
		display: flex;
		flex-direction: column;
		//text-align: center;

		h3 {
			margin: t-spacing(md) 0;

			color: transparentize($color-black, 0.5);

			font-size: t-rem(16px) !important;
			font-weight: normal;
		}

		h2 {
			text-align: center;
			margin: t-spacing(md) 0;
		}

		// > * + * {
		// 	margin-top: t-spacing(sm);
		// }

		&:not(:last-child) {
			border-bottom: 1px solid $color-grey4;
			padding: 0 0 t-spacing(sm);
		}
	}

	&__removeall {
		padding: t-spacing(md) 0;

		background-color: $color-white;
	}

	&__removeall-button {
		margin-right: t-spacing(md) !important;
		border-radius: 24px !important;

		background-color: rgba(242, 239, 249, 0.4) !important;
		color: $color-purple1 !important;

		font-weight: 600 !important;
		text-transform: none !important;
	}
}

.c-product__key {
	display: flex;
	flex-direction: row;
}

.c-product-double .c-product__info-wrapper {
	grid-template-columns: repeat(2, 1fr) !important;
}

.c-product-triple .c-product__info-wrapper {
	grid-template-columns: repeat(3, 1fr) !important;
}

.c-product-quadruple .c-product__info-wrapper {
	grid-template-columns: repeat(4, 1fr) !important;
}

.c-product__key-wrapper{
	grid-row-gap: 0 !important;
    row-gap: 0 !important;
	padding-bottom: 0 !important;
}

.c-product__key-disclaimer{
	font-size: 0.75rem;
	line-height: 1rem;
}

.c-product__info-item {
	text-align: left;
}

.c-product__sample{
	background-color: #f2eff9;
    color: #4B3687;
	font-size: 0.875rem;
    letter-spacing: 0.02687rem;
    line-height: 0.8125rem;
    border-radius: 24px;
    border-bottom: none !important;
    padding: 10px 20px;
	text-decoration: none;
	margin-top: 10px;
	display: block;
    width: fit-content;
}

.c-product__title--flex{
	display: flex;
	align-items: center;

	.c-product__sample{
		margin-top: 0;
		margin-left: 20px;
	}
}

