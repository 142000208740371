.c-onboarding {
  $root: &;
  background: $color-white;
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  transition: opacity 0.5s ease-out;
  width: 100%;
  z-index: t-layer('highest');

  &__content {
    margin: 0 auto;
    max-width: 360px;
  }

  &__controls {
    display: grid;
    grid-gap: t-spacing(sm);
    grid-template-columns: 2fr 1fr 2fr;
    grid-template-areas: "previous pips next";
    padding: t-spacing(md);
  }

  &__header {
    background: linear-gradient(270deg, $color-purple2, $color-purple4);
    padding: t-spacing(md) t-spacing(md) t-spacing(lg);
  }

  &__image {
    $sizeSm: 150px;
    $size: 250px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: $sizeSm;
    margin: 0 auto;
    width: $size;

    @media (min-height: 569px) {
      height: $size;
    }

    &--1 {
      background-image: url('../../images/onboarding-1.svg');
    }

    &--2 {
      background-image: url('../../images/onboarding-2.svg');
    }

    &--3 {
      background-image: url('../../images/onboarding-3.svg');
    }
  }

  &__logo {
    margin: 0 auto;
    max-width: 120px;
  }

  &__next {
    grid-area: next;
  }

  &__next,
  &__previous {
    &.e-button--small.MuiButton-root {
      @include t-box-shadow;
      @include t-typography(tab, bold);

      text-transform: uppercase;
    }
  }

  &__pips {
    align-items: center;
    display: flex;
    grid-area: pips;
    justify-content: space-evenly;
  }

  &__pip {
    $size: 8px;
    background-color: $color-purple-tr1;
    border-radius: 100%;
    display: inline-block;
    height: $size;
    width: $size;

    &--active {
      background-color: $color-purple1;
    }
  }

  &__previous {
    &.e-button--small.MuiButton-root {
      color: $color-purple5;
      grid-area: previous; 
    }
  }

  &__slide {
    padding: t-spacing(md);
    text-align: center;
  }

  &__slide-title {
    @include t-typography(h1);

    margin: t-spacing(sm) 0;
  }

  &.is-ie {
    #{$root} {
      &__controls {
        display: flex;
        text-align: center;
      }
    
      &__pips,
      &__next.e-button--small.MuiButton-root,
      &__previous.e-button--small.MuiButton-root {
        flex: 1;
      }
    
      &__pips {
        text-align: center;
      }
    
      &__pip {
        &:not(:last-child) {
          margin-right: t-spacing(sm);
        }
      }
    }
  }
}
