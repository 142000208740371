.c-product-search-form {
	display: flex;
	justify-content: space-between;

	&__control {
		display: inline-flex;
		justify-content: center;
		align-items: center;

		margin-right: t-rem(-15px);
		padding: t-spacing(sm);
		border: none;
		border-radius: 100%;
		
		background-color: $color-grey5;
		
		cursor: pointer;

		&:last-child {
			margin-left: t-spacing(xs);
		}
	}

	&__button.MuiButton-root {
		margin-left: t-spacing(sm);
		min-width: 100px;
		padding: t-spacing(sm) t-spacing(md);

		background: linear-gradient(270deg, $color-purple2, $color-purple4);

		font-size: t-rem($global-font-size);
		font-family: $font-family-default;
		font-weight: bold;
		letter-spacing: t-rem(1.43px);
		text-transform: unset;
	}

	&__filterbutton {

		width: t-rem(180px);
		border-radius: t-rem(24px) !important;
		
		background-color: $color-purple1 !important;

		text-transform: none !important;

		margin-right: auto !important;
    	margin-left: auto !important;
    	margin-top: 10px !important;
	}

	.MuiInput-root {
		padding: t-spacing(sm) t-spacing(lg);
		border: solid 1px $color-grey4;
		border-image-width: none;
	}

	.MuiFormLabel-root {
		font-family: $font-family-default;
	}

	.MuiInput-root .MuiSvgIcon-root {
		color: $color-grey3;
	}

	.MuiFormControl-root {
		width: 100%;
		border: none;
	}

	.MuiInput-root {
		border-radius: 24px;

		background-color: $color-white;
		
		font-family: $font-family-default;

		&::before {
			border-bottom: none;
		}
		&::after {
			border-bottom: none;
		}
	}
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
	border-bottom: none !important;
}
