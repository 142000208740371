.toggle {
    $root: &;
    $toggleWidth: t-rem(34px);

    display: flex;
    align-items: center;

    &__label {
        margin-right: t-spacing(md);

        font-size: t-rem(12px);
        line-height: t-rem(16px);
    }

    &__button {
        position: relative;
    
        width: $toggleWidth;
        height: t-rem(14px);
        border: none;
        border-radius: t-rem(10px);

        background-color: $color-purple8;
    
        transition: background-color 250ms;
    }
    
    &__handle {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        width: t-rem(20px);
        height: t-rem(20px);
        border-radius: 50%;

        background-color: $color-purple6;

        transition: transform 250ms;

        #{$root}.active & {
            transform: translate(calc(#{$toggleWidth} + -100%), -50%);
        }
    }
}