.c-loader {
  $size: 50px;
  $border: 5px;

  align-items: center;
  animation: loading 1s infinite linear;
  border-top: $border solid $color-grey-tr2;
  border-right: $border solid $color-grey-tr2;
  border-bottom: $border solid $color-grey-tr2;
  display: flex;
  justify-content: center;
  margin: t-spacing(lg) auto;
  position: relative;
  transform: translateZ(0);

  &,
  &::after {
    border-radius: 50%;
    height: $size;
    width: $size;
  }

  &--purple {
    border-left: $border solid $color-purple2;
  }

  &--white {
    border-left: $border solid $color-white;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
