.c-loading-splash {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;
    z-index: t-layer('highest');

    width: 100%;
    height: 100%;

    background: $color-white url('../../images/gradient.png') no-repeat right top / 100% 100vh;

    &__logo {
        width: 100%;
        max-width: 200px;
        margin: t-spacing(lg) auto;
    }

    &__label {
        @include t-typography(h3);

        display: block;
    }
}