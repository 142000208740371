.c-navigation {
    $root: &;

    position: fixed;
    bottom: 0;
    left: 0;
    z-index: t-layer('high');

    width: 100%;
    
    background-color: $color-white;

    .MuiBottomNavigation-root {
        background-color: transparent;
        box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.24);
    }

    &__item {
        svg {
            fill: $color-grey3; // only instance of this colour for a11y contrast adjustment
        }

        &.MuiBottomNavigationAction-root {
            color: $color-grey3;
        }

        &#{&}--active {
            &.MuiBottomNavigationAction-root {
                color: $color-purple2;
            }

            svg {
                fill: $color-purple2;
            }

            .MuiBottomNavigationAction-label {
                color: $color-purple2;
            }

            &:after {
                content: '';
                display: block;

                position: absolute;
                top: 0;
                left: 0;

                width: 100%;
                height: t-rem(3px);

                background-color: $color-purple2;
            }
        }

        &:hover {
            color: $color-purple1;
        }

        .MuiBottomNavigationAction-label {
            @include t-typography(details, normal);

            color: $color-grey3;

            font-family: $font-family-default;
        }
    }

    &__sub-icon {
        $size: 50px;

        display: inline-flex;
        align-items: center;
        justify-content: center;
        
        height: $size;
        width: $size;
        margin: 0 0 0 t-spacing(md);
        border-radius: 100%;
        
        background-color: $color-white;

        .MuiSvgIcon-root {
            fill: $color-purple4;
        }
    }

    &__sub-item {
        @include t-typography(button);

        display: flex;
        align-items: center;
        justify-content: flex-end;

        width: 100%;

        color: $color-white;

        text-decoration: none;

        &:not(:last-child) {
            margin: 0 0 t-spacing(lg);
        }

        &:hover {
            #{$root}__sub-icon {
                opacity: 0.7;
            }
        }

        &--active {
            #{$root}__sub-icon {
                background-color: $color-purple4;

                .MuiSvgIcon-root {
                    fill: $color-white;
                }
            }
        }
    }

    &__sub-items {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;

        position: fixed;
        left: 0;
        bottom: $global-nav-height;
        z-index: t-layer('highest');

        height: calc(100% - #{$global-nav-height});
        width: 100%;
        padding: t-spacing(lg);

        background-color: $color-grey-tr1;
    }

    &.is-ie {
        .MuiBottomNavigation-root {
            display: block;
            text-align: center;

            .MuiButtonBase-root {
                display: inline-block;
                
                margin: 0 t-spacing(md);
            }
        }
    }
}