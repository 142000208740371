$color-black:       #000000;
$color-grey-tr1:    #000000DE;
$color-grey-tr2:    #00000029;
$color-grey1:       #707070;
$color-grey2:       #757575;
$color-grey3:       #b0bec5;
$color-grey4:       #E2E2E2;
$color-grey5:       #F4F4F4;
$color-grey6:       #dee7ed;
$color-white:       #FFFFFF;
$color-blue:        #7E9CC0;
$color-purple1:     #4B3687;
$color-purple2:     #51338B;
$color-purple4:     #6200EE;
$color-purple5:     #E3B8FF;
$color-purple6:     #6200ea;
$color-purple7:     #f2eff9;
$color-purple8:     #b388ff;
$color-purple9:     #6d00f4;
$color-purple-tr1:  #4B368717;
$color-purple-tr2:  #6200EE34;
$color-red:         #D14242;
$color-yellow:      #E8C243;
$color-yellow2:     #E8C2434D;