.c-slider--protein-summary {
    padding: t-spacing(md) 0;

    &__label {
        font-size: t-rem(16px);
        line-height: 1.5;
    }

    &__info {
        font-size: t-rem(42px);
        line-height: 1.29;

        small {
            font-size: t-rem(14px);
        }
    }
}