.c-tabs {
	background-color: $color-white;
	display: flex;
	flex-direction: column;
	padding-bottom: t-rem(60px);
	box-sizing: border-box;

	&__panel {
		padding-top: t-spacing(md);
		> * ~ *,
		> .MuiFormControl-root {
			margin-top: t-spacing(lg);
		}

		> p {
			white-space: pre-line;
		}
	}

  .MuiTabs-root {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);
    margin-left: calc(-1 * (#{t-spacing(md)}));
    margin-right: calc(-1 * (#{t-spacing(md)}));
    padding: t-spacing(md);
		position: sticky;
		top: 0;
		z-index: 3;
		background-color: $color-white
  }

	.MuiTab-root {
		@include t-typography(tab, bold);
		background-color: $color-purple4;
		border-radius: 24px;
		border-bottom: none !important;
		padding: t-spacing(xs) t-spacing(lg);
		color: $color-white;
		font-family: inherit;
		letter-spacing: inherit;
		text-transform: none;
		min-height: 32px;

		&:not(.Mui-selected) {
			background-color: $color-purple7;
			color: $color-purple1;
			font-weight: bold;
			opacity: 100 !important;
		}
		&.Mui-selected {
			border-image-width: 0 !important;
		}

		&:not(:first-child) {
			border-left: 1px solid $color-white;
		}
		.MuiTab-wrapper {
			flex-wrap: nowrap;
			font-weight: 600;
		}
	}

	.MuiTabs-flexContainer {
		justify-content: space-between;
		//flex-direction: row-reverse !important;
	}

	.MuiTabs-indicator {
		height: 0;
	}
}
