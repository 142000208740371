.c-patient-result {
    display: flex;
    justify-content: space-between;

    margin: t-spacing(lg) 0;
    padding-top: t-spacing(lg);
    border-top: 1px solid $color-grey4;

    &--energy {
        margin: 0 t-rem(-15px) !important;
        padding: 0 t-rem(15px) t-spacing(lg) !important;
        border-top: none;
        border-bottom: solid 1px $color-grey4;

        &__hint {
            margin-top: t-spacing(xs);

            font-size: t-rem(12px);
        }
    }

    &__field {
        &:not(:last-child) {
            margin-right: t-spacing(sm);
        }

        &,
        .MuiFormControl-root {
            width: 100%;
        }
    }
}

.t-result {

    .MuiInputBase-root,
    .MuiInputBase-root.Mui-focused,
    .MuiTextField-root,
    .MuiOutlinedInput-root,
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
    &.c-text-field .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
    .MuiOutlinedInput-notchedOutline {
        border-color: $color-purple4;
    }

    .MuiFormLabel-root {
        font-family: $font-family-default;
        font-weight: bold;

        &.Mui-focused,
        & {
            color: $color-purple1;
        }
    }

    .MuiInputBase-root,
    .MuiInputBase-input {
        font-family: $font-family-default;
        font-weight: bold;
    }

    &.c-text-field,
    & {
        .MuiTextField-root input[readonly] {
            background-color: $color-white;
        }
    }
}