$s-typography: (
  h1: (
    color: $color-purple1,
    font-size: t-rem(24px),
    line-height: t-rem(30px),
  ),
  h2: (
    color: $color-purple1,
    font-size: t-rem(18px),
    line-height: t-rem(24px),
  ),
  h3: (
    font-size: t-rem(16px),
    color: $color-purple1,
    line-height: t-rem(21px),
  ),
  body: (
    font-size: t-rem(16px),
    letter-spacing: t-rem(0.24px),
    line-height: t-rem(19px),
  ),
  details: (
    font-size: t-rem(12px),
    letter-spacing: t-rem(0.38px),
    line-height: t-rem(15px),
  ),
  button: (
    font-size: t-rem(18px),
    letter-spacing: t-rem(1.6px),
    line-height: t-rem(24px),
  ),
  tab: (
    font-size: t-rem(14px),
    letter-spacing: t-rem(0.43px),
    line-height: t-rem(13px),
  )
);

@mixin t-typography($style, $weight: normal) {
  $style-props: map-get($s-typography, $style);

	@if not map-has-key($s-typography, $style) {
		@error "Invalid style specified! #{$style} doesn't exist. Choose one of #{map-keys($s-typography)}";
  }

	@each $key, $value in $style-props {
    #{$key}: $value;
  }

  font-weight: $weight;
}
