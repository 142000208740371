.c-tooltip {
  display: inline-flex;
  position: relative;
  margin-top: 3px;

  &:last-child {
    margin-left: t-spacing(sm);
  }

  &__close {
    &.MuiButtonBase-root {
      @include t-brand-gradient;

      color: $color-white;
      padding: t-spacing(sm);
      position: absolute;
      right: t-spacing(md);
      top: t-spacing(md);  
    }
  }

  &__content {
    @include t-box-shadow;

    background-color: $color-white;
    border-radius: $global-border-radius;
    margin: 0 t-spacing(lg);
    max-height: 220px;
    max-width: 770px;
    overflow: auto;
    padding: t-spacing(lg);
    position: relative;
    transform: translate3d(0,0,0);
    width: 100%;
    z-index: t-layer(highest);
    -webkit-overflow-scrolling: touch;

    p {
      white-space: pre-line;
    }
  }

  &__open {
    $size: 16px;

    @include t-typography(details, bold);

    align-items: center;
    background-color: $color-white;
    border: 1px solid $color-purple1;
    border-radius: 100%;
    color: $color-purple1;
    cursor: pointer;
    display: inline-flex;
    height: $size;
    justify-content: center;
    margin: -t-spacing(sm) 0 0;
    padding: 0;
    width: $size;
  }

  &__overlay {
    display: none;

    &--active {
      align-items: center;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      left: 0;
      height: 100%;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: t-layer(highest);
    }
  }

  &__text {
    white-space: pre-line;
  }
}
