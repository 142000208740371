.c-hcpsplash {
    $vertical-spacing: t-rem(30px);

    display: flex;
    align-items: center;
    justify-content: center;
    
    position: fixed;
    top: 0;
    left: 0;
    z-index: t-layer('highest');

    height: 100%;
    width: 100%;

    background: url('../../images/gradient.png') no-repeat right top / 100% 100vh;
    
    opacity: 1;
    transition: opacity 0.5s ease-out;

    &__content {
        max-width: 400px;
        padding: t-spacing(md);
    }

    &__ctas {
        .e-button {
            background-color: $color-purple4;
            color: $color-white;

            &:not(:first-child) {
                margin-top: calc(#{$vertical-spacing} / 2);
            }

            &:hover.Mui-disabled,
            &.Mui-disabled {
                background-color: $color-white;
            }

            transition: background-color 250ms;

            &:hover {
                background-color: darken($color-purple4, 10%);
            }
        }
    }

    &__invisible {
        opacity: 0;
    }

    &__invisible,
    &__visible {
        transition: opacity 0.5s ease-in-out;
    }

    &__logo {
        margin: 0 auto calc(#{$vertical-spacing} * 2);
    }

    &__text {
        @include t-typography(h1);
    }

    &__tick {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        
        width: 24px;
        height: 24px;
        
        fill: $color-purple4;
    }

    &__title {
        @include t-typography(h1, bold);

        border-bottom: 1px solid $color-white;
        padding-bottom: $vertical-spacing;
    }

    &__message,
    &__text,
    &__title {
        text-align: center;
        
        margin-bottom: $vertical-spacing;
    }

    &__visible {
        opacity: 1;
    }
}