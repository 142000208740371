.support-privacy {
    .u-content-wrapper {
        padding: t-rem(34px);
        border-radius: t-rem(12px);
    
        background-color: $color-white;

        p {
            line-height: 1.5;
        }
    }
}