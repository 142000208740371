.c-field-group {
  &__content {
    display: flex;
    flex-direction: column;

    @include t-respond-from('tablet') {
      flex-direction: unset;
    }

    > * {
      flex: 0 0 100%;

      @include t-respond-from('tablet') {
        flex: 1;
      }
    }

    > * + * {
      margin-top: t-spacing(sm);

      @include t-respond-from('tablet') {
        margin-left: t-spacing(sm);
        margin-top: 0;
      }
    }
  }

  &__title {
    margin-bottom: t-spacing(md);
  }

  .c-dropdown {
    flex-basis: auto;

    @include t-respond-from('tablet') {
      flex-basis: unset;
    }

    &:not(:last-child) {
      margin-bottom: t-spacing(md);
    }
  }
}
