.c-accordion {
  $root: &;

  border-radius: $global-border-radius;
  box-shadow: 0 3px 6px $color-grey-tr2;

  &__content {
    background-color: $color-white;
    display: none;
  }

  &__description {
    padding: t-spacing(sm);
  }

  &__field-list {
    display: flex;
    justify-content: space-between;
    padding: 0;
    width: 100%;
  }

  &__field-list-label {
    @include t-typography(h3, regular);

    margin-bottom: t-spacing(xs);
  }

  &__field-list-item {
    flex: 1;
    width: 100%;

    &:not(:first-child) {
      margin-left: t-spacing(md);
    }
  }

  &__field-list-value {
    @include t-typography(h3, bold);

    background-color: $color-white;
    border: 1px solid $color-purple5;
    border-radius: $global-border-radius;
    padding: t-spacing(sm);
    width: 100%;
  }

  &__field-value {
    .MuiFormControl-root {
      width: 100%;
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
    .MuiOutlinedInput-notchedOutline {
      border-color: $color-purple4;
    }

    .MuiFormLabel-root {
      font-family: $font-family-default;
      font-weight: bold;

      &.Mui-focused,
      & {
        color: $color-purple1;
      }
    }

    .MuiInputBase-root {
      font-family: $font-family-default;
      font-weight: bold;
    }
  }

  &__footer {
    &:not(:empty) {
      padding: t-spacing(md) t-spacing(sm);
    }

    > * ~ *,
    > .e-button.MuiButton-root {
      margin-top: t-spacing(md);
    }
  }

  &__header {
    align-items: center;
    background-color: $color-white;
    border-bottom: 2px solid $color-purple1;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: t-spacing(md) t-spacing(sm);
  }

  &__icon {
    display: flex;
  }

  &__list {
    margin: 0;
    padding: 0;  
  }

  &__list-item {
    border-bottom: 1px solid $color-grey-tr2;
    padding: t-spacing(lg) t-spacing(sm);
  }

  &__list-title {
    margin-bottom: t-spacing(sm);
  }

  &__text-content {
    padding: t-spacing(lg) t-spacing(sm);
  }

  &--expanded {
    #{$root}__content {
      display: block;
    }
  }
}
