.c-text-field {
  .MuiFormControl-root {
    width: 100%;
  }

  .MuiTextField-root {
    background-color: $color-white;
    color: $color-purple1;
    width: 100%;

    input[readonly] {
      background-color: $color-purple-tr1;
    }
  }

  .MuiInputBase-root {
    @include t-typography(h3);

    font-family: $font-family-default;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: $color-purple-tr2;
    font-family: $font-family-default;
  }
  
  .MuiFormLabel-root,
  .MuiOutlinedInput-notchedOutline span {
    font-family: $font-family-default;
    font-weight: bold;
  }
}
