.c-page-header {
    $root: &;

    box-sizing: border-box;
    width: 100%;
    padding: t-rem(44px) t-spacing(md) t-spacing(md);

    color: $color-purple1;

    &--bottom-border {
        border-bottom: t-rem(1px) solid $color-grey4;
    }

    &__title {
        text-align: center;

        width: 100%;

        color: black;

        font-size: t-rem(18px);
        font-weight: 600;
    }

    &__subtitle,
    &__text,
    &__wrapper {
        margin: 0 0 t-spacing(xs);
        padding: 10px 0;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        
        margin-bottom: 0;

        &__image {
            width: t-rem(146px);
        }
    }

    &__back {
        display: block;

        position: absolute;

        width: t-rem(48px);
        height: t-rem(48px);
        border-radius: 50%;

        background-color: $color-white;

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            width: t-rem(29px);
            height: t-rem(29px);
        }
    }

    &--white {
        background-color: white;
    }

    &--welcome {
        position: relative;

        max-width: 784px;
        margin: auto;
        padding-top: t-rem(56px);
        border-bottom: none;

        .c-page-header__text {
            max-width: 784px;
            margin: t-rem(52px) auto 0;
    
            color: black;
    
            font-size: t-rem(24px);

            @include t-respond-from(tablet) {
                margin-top: t-rem(49px);

                font-size: t-rem(42px);
                font-weight: 300;
            }
        }
    }

    &--support {
        text-align: left;

        padding: t-rem(32px) t-rem(15px);
        
        #{$root}__wrapper {
            display: block;
        }

        #{$root}__title {
            display: block;
            text-align: left;

            width: auto;
            margin-top: 0.6rem;
            margin-left: 3.5rem;

            font-size: t-rem(36px);
            font-weight: normal;
        }

        #{$root}__subtitle {
            margin-top: t-spacing(md);

            color: $color-black;

            font-weight: 400;
        }
    }
}