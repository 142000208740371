@mixin t-box-shadow($depth: 3px, $color: $color-grey-tr2) {
  box-shadow: 0 1px $depth $color;
}

@mixin t-block {
  @include t-box-shadow();

  background-color: $color-white;
  border-radius: $global-border-radius;
  transition: box-shadow ease-in-out 0.5s;

  &:hover {
    @include t-box-shadow(10px);
  }
}

@mixin t-brand-gradient($ltr: false) {
  @if $ltr == true {
    background: linear-gradient(90deg, $color-purple2, $color-purple4);
  } @else {
    background: linear-gradient(270deg, $color-purple2, $color-purple4);
  }
}

@function t-rem($size) {
  @return $size / $global-font-size * 1rem;
}

@mixin t-owl {
  > * ~ *,
  > .e-button.MuiButton-root,
  > .MuiFormControl-root {
    margin-top: t-spacing(md);
  }
}
