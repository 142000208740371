.c-radio-list {
    &.MuiFormControl-root {
        width: 100%;
    }

    &__group.MuiFormGroup-root {
        flex-direction: row;
    }
    
    &__group--calory.MuiFormGroup-root {
        padding: t-spacing(md) 0;
    }

    &__label.MuiFormLabel-root {
        @include t-typography(h3, bold);

        color: $color-purple1;
        font-family: $font-family-default;
        letter-spacing: unset;
        margin-bottom: t-spacing(sm);
    }

    &__option {
        &.MuiFormControlLabel-root {
            border-radius: $global-border-radius;
            display: flex;
            flex: 1;
            justify-content: space-between;
            margin: 0;
            overflow: hidden;
            padding: t-spacing(sm) t-spacing(sm) t-spacing(sm) t-spacing(lg);
            position: relative;
        }

        &:not(:last-child) {
            margin-right: t-spacing(sm);
        }

        &::before {
            background-color: $color-white;
            content: '';
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: t-layer('lowest');
        }

        .MuiTypography-root {
            @include t-typography(h2, bold);

            font-family: $font-family-default;
            letter-spacing: unset;
            z-index: t-layer('low');
        }

        .MuiRadio-root {
            color: $color-purple4;
            margin-left: auto;
        }

        &--active {
            color: $color-white;

            &::before {
                background: linear-gradient(270deg, $color-purple2, $color-purple4);
            }

            .MuiTypography-root {
                color: $color-white;
            }

            .MuiRadio-colorSecondary.Mui-checked {
                color: $color-white;
            }
        }
    }
}