.c-contact-block {
    padding: t-rem(20px) 0;
    border-bottom: solid 1px transparentize($color-grey4, 0.6);

    &:last-child {
        border-bottom: none;
    }

    &__content {
        flex: 1;
        padding: t-spacing(md);
    }

    &__content-wrapper {
        display: flex;
        
        color: $color-grey1;
    }

    &__details {
        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__item {
        position: relative;

        margin: 0 0 t-spacing(md);
        padding-left: 34px;

        .MuiSvgIcon-root {
            $size: 24px;

            display: block;
            
            position: absolute;
            top: 0;
            left: 0;

            width: $size;
            height: $size;

            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            fill: $color-purple2;
        }

        p {
            color: $color-black;
        }

        a {
            color: $color-black;
            text-decoration: none !important;
        }

        &--fax {
            color: $color-black;
        }

        &--address {
            font-style: normal;

            p {
                line-height: 1.5;
            }
        }
    }

    &__opening-times {
        padding: t-spacing(lg) t-spacing(md);
        border-left: 1px solid $color-grey-tr2;
    }

    &__opening-title {
        color: $color-purple1;
    }

    &__subtitle,
    &__title {
        margin: 0 0 t-spacing(md);
        
        color: $color-black;
    }

    &__subtitle {
        font-size: t-rem(18px);
    }

    &__title {
        @include t-typography(h3);
    }
}